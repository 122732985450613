<template>
    <div>Hello Puppeteer...</div>
</template>

<script>
    export default {
        name: 'SuperUserRedirect',
        computed: {
            token() {
                return this.$route.query.t;
            },
            companyId() {
                return this.$route.query.c;
            },
            key() {
                return this.$route.query.k;
            },
        },
        async created() {
            const res = await this.$api.post(`/auth/puppet-sign-in?t=${this.token}&c=${this.companyId}&k=${this.key}`);

            if (res.status < 300) {
                setTimeout(async () => {
                    this.$store.commit('persistUser', res.data.user);
                    this.$store.commit('persistBuildId', res.data.buildId);
                }, 200);
            } else {
                await this.$router.replace({ name: 'SignIn' });
            }
        }
    };
</script>

<style scoped></style>
